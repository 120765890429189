import { ReactChild, ReactFragment, ReactPortal } from "react";
import { PaginationApiResponse } from "./pagination";
import { TopBusinessWalletResponse } from "./wallet";

type SeriesSnapShots = {
  this_month: number;
  this_week: number;
  today: number;
};

type TotalGrossTransactionsSnapShots = {
  last: number;
  this_month: number;
  this_week: number;
};

export type TotalActiveTransactions = {
  _id: string;
  total: number;
};

type ActiveCustomerWallets = {
  _id: string;
  total_transactions: number;
  first_name: string;
  last_name: string;
};

export type SeriesSnapShotTransactions = {
  day: string;
  month: string;
  total_amount: number;
  total_transactions: number;
};

export interface MerchantUserInterface {
  trading_name: string;
  email: string;
  phone_number: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  dial_code?: string;
  deactivated_businesses?: string[];
}

export interface MerchantInterface extends MerchantUserInterface {
  two_fa: boolean;
  update_at: string;
  created_at: string;
  last_login: string;
  id: string;
  is_active: string;
  is_invite_active: string;
  composite_payer?: boolean;
  email_confirm: string;
  businesses: BusinessInterface[];
  dashboardSummary: {
    data: DashboardSummaryInterface;
  };
  profile_picture?: string;
  role_id?: string;
  show_product_tour?: boolean;
}

export interface UserInterface {
  merchant: MerchantInterface;
  member: ITeamMemberResponseObject;
}

export interface ProfileUpdatePayload
  extends Partial<
    Pick<MerchantUserInterface, "first_name" | "last_name" | "phone_number">
  > {
  email?: string;
  show_product_tour?: boolean;
  id?: string;
  preferred_name?: string;
}

export interface DashboardSummaryInterface extends Record<string, unknown> {
  total_credits: SeriesSnapShots;
  total_debits: SeriesSnapShots;
  total_transactions: TotalGrossTransactionsSnapShots;
  total_wallet_count: number;
  credits: TotalActiveTransactions[];
  mostActiveWallets: ActiveCustomerWallets[];
  paymentVolume: TotalActiveTransactions[];
  fees: TotalActiveTransactions[];
  debits: TotalActiveTransactions[];
  topWallets: TopBusinessWalletResponse[];
  message: string;
  totalNumberOfTransactions: number;
  totalNumberOfWallet: number;
  totalNumberOfCustomer: number;
  transactionForLastThirtyDays: SeriesSnapShotTransactions[];
}

export interface BusinessInterface extends Record<string, unknown> {
  bank_details: string;
  business_type: string;
  bvn: string;
  category: string;
  company_size: string;
  created_at: string;
  default: string;
  description: string;
  disabled_reason: string;
  activation_request: boolean;
  display_state: string;
  id: string;
  incorporation_document: string;
  is_active: boolean;
  legal_business_name: string;
  live: boolean;
  merchant_id: string;
  nimc: string;
  pending_modifications: string;
  date_activated?: string;
  registration_type: string;
  trading_name: string;
  update_at: string;
  business_logo_url: string;
  role: IRolesResponseObject;
  show_product_tour: boolean;
  preferred_name?: string;
}

export interface TeamMemberInterface extends Record<string, unknown> {
  first_name?: string;
  last_name?: string;
  email: string;
  phone_number?: string;
  role: string;
  job_title?: string;
}

export interface TeamMemberModalInterface
  extends Omit<TeamMemberInterface, "first_name" | "last_name" | "role"> {
  full_name: string;
  email: string;
  phone_number: string;
}

export interface TeamMemberPayload extends Record<string, unknown> {
  first_name?: string;
  last_name?: string;
  email: string;
  phone_number?: string;
  role_id: [string, ...string[]];
  business_id: string;
  dial_code?: string;
}

export interface AddonTeamMemberRolePayload
  extends Pick<TeamMemberPayload, "role_id" | "business_id"> {
  user_id: string;
}

export interface RemoveOnTeamMemberRolePayload
  extends Pick<TeamMemberPayload, "business_id"> {
  role_id: string;
  user_id: string;
}

export interface ActivateTeamMemberPayload
  extends Partial<Pick<TeamMemberPayload, "first_name" | "last_name">> {
  email: string;
  phone_number: string;
  password: string;
  full_name?: string;
  dial_code?: string;
}

export interface IAllowedRolesResponseObject extends Record<string, unknown> {
  name: string;
  description: string;
  is_allowed?: boolean;
}

export interface IPermissionResponseObject extends IAllowedRolesResponseObject {
  is_allowed: boolean;
  allowed_roles: IAllowedRolesResponseObject[];
  icon?: ReactChild | ReactFragment | ReactPortal;
  count?: number;
}

export interface IRolesResponseObject extends Record<string, unknown> {
  id: string;
  name: string;
  permissions: IPermissionResponseObject[];
  user_count: number;
  permission_count: number;
  business_id?: string;
}

export interface IAllRolesResponse {
  data: IRolesResponseObject[];
  meta: PaginationApiResponse;
}

export interface IDepartment {
  business_id: string;
  created_at: string;
  creator_id: string | null;
  deleted_at: string | null;
  hod_id: string;
  id: string;
  is_active: boolean;
  name: string;
  update_at: string;
}

export interface ITeamMemberResponseObject
  extends Omit<
    MerchantInterface,
    | "dashboardSummary"
    | "businesses"
    | "created_at"
    | "is_active"
    | "is_invite_active"
    | "email_confirm"
    | "trading_name"
    | "update_at"
  > {
  merchant_id: string;
  is_owner?: boolean;
  two_fa_secret: string;
  email_token: string;
  password_reset_token: string;
  email_confirm: boolean;
  is_invite_active: boolean;
  login_attempt: number;
  is_active: boolean;
  composite_payer?: boolean;
  merchant: MerchantInterface;
  business: BusinessInterface;
  user: MerchantInterface;
  role: IRolesResponseObject;
  created_at: Date;
  profile_picture_url?: string;
  role_id?: string;
  show_product_tour?: boolean;
  department?: IDepartment | null;
}

export interface IAllTeamMembersResponse {
  data: ITeamMemberResponseObject[];
  meta: PaginationApiResponse;
}

export interface UpdateTeamMemberInterface {
  business_id?: string;
  merchant_id?: string;
  is_active?: boolean;
  user_id?: string;
  is_invite_active?: boolean;
  action?: string;
  password?: string;
  email?: string;
  role_id?: string;
  show_product_tour?: boolean;
  department_id?: string;
}

export interface CustomRolePayload
  extends Omit<IAllowedRolesResponseObject, "is_allowed"> {
  business_id: string;
  is_custom_role: boolean;
  is_active: boolean;
  permissions: IPermissionResponseObject[];
}

export enum UserActionStatus {
  payer = "Payer",
  approver = "Approver",
  submitter = "Submitter",
  initiator = "Initiator",
  owner = "Owner",
  admin = "Admin",
  view_only = "View Only"
}

export enum UserPermissionPages {
  overview = "overview_page",
  business = "business_page",
  transactions = "transactions_page",
  approvals = "approval_page",
  customers = "customers_page",
  invoices = "invoices_page",
  virtual_accounts = "va_page",
  recipients = "recipients_page",
  payouts = "payouts_page",
  bills = "bills_page",
  settings = "settings_page",
  profile = "profile_page",
  apps = "apps_page",
  expenses = "expenses_page"
}

export enum TeamMemberPageOptions {
  users = "Users",
  roles = "Roles",
  departments = "Departments"
}
