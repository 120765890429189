import React from "react";
import {
  DownloadReceiptContainer,
  RequestDetailsContainer,
  RequestDetailsContainerEl,
  UploadItemContainer
} from "./styles";
import { ReactComponent as FileIcon } from "assets/svg/pdf-grey.svg";
import { PayoutStatusContainer } from "pages/Payout/styles";
import { NairaFormatter } from "utils/number-formatter";
import DocumentPreviewModal from "components/Modal/DocumentPreviewModal";
import { useModals } from "context/modalContext";
import AuditTrailComponent from "components/AuditTrailComponent";
import { useParams } from "react-router";
import { useGetSingleExpense } from "lib/query/expense";
import { defaultBusinessKey } from "utils/constants";
import storage from "utils/storage";
import { decrypt_user0bj } from "utils/auth-util";
import { BusinessInterface } from "interfaces/users";
import { getPayoutStatusColor, transformPayoutStatus } from "utils/payout";
import moment from "moment";

import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFRenderer from "components/PDFRenderer";
import { PDFReceiptType } from "components/PDFRenderer/@types";
import { truncateString } from "utils/text-util";
import { getTimeAgo } from "utils/timeAgo";
import { ApprovalAuditTrail, SingleExpenseResponse } from "./@types";

const ExpenseDetails = () => {
  const params: { id: string } = useParams();
  const { push: openDocument, close: closeDocument } = useModals();
  const defaultBusinessInfo: BusinessInterface =
    decrypt_user0bj<BusinessInterface>(storage.get(defaultBusinessKey));

  const { data: expense } = useGetSingleExpense(
    params?.id,
    defaultBusinessInfo?.id || ""
  );

  const getPaymentStatus = (status: string) => {
    switch (status.toLowerCase()) {
      case "successful":
        return "paid";
      case "failed":
        return "failed";
      default:
        return "pending";
    }
  };

  const triggerDocumentPreviewModal = (file_url: string) => {
    openDocument(
      <div>
        <DocumentPreviewModal
          closeSuccessModal={() => closeDocument()}
          url={file_url}
          is_from_aws
        />
      </div>
    );
  };

  const getDepartmentTrailInfo = () => {
    const trailItem: {
      name: string;
      action: string;
      timeAgo: string;
      comment?: string;
      status?: string;
    }[] = [
      {
        name:
          (expense?.result?.creator?.first_name || "") +
          (expense?.result?.creator?.last_name || ""),
        action: "submitted an expense",
        timeAgo: getTimeAgo(expense?.result?.created_at || "")
      }
    ];

    if (expense?.result?.status === "accepted") {
      trailItem.push({
        name:
          (expense?.result?.department?.hod?.first_name || "") +
          (expense?.result?.department?.hod?.last_name || ""),
        action: "approved this expense",
        timeAgo: getTimeAgo(
          expense?.result?.hod_approval_date ||
            expense?.result?.updated_at ||
            ""
        ),
        status: "approved"
      });
    }

    return trailItem;
  };

  const getApprovalComment = (el: ApprovalAuditTrail) => {
    if (el.approved) {
      return el.approve_note;
    }

    if (el.denied) {
      return el.deny_note;
    }

    return "";
  };

  const getAuditTrailAction = (el: ApprovalAuditTrail) => {
    if (el.approved) {
      return "approved this expense";
    }

    if (el.denied) {
      return "rejected this expense";
    }

    return "";
  };

  const getAuditTrailStatus = (el: ApprovalAuditTrail) => {
    if (el.approved) {
      return "approved";
    }

    if (el.denied) {
      return "rejected";
    }

    return "";
  };

  const getFinanceTeamTrailInfo = () => {
    const trailItem = expense?.approval_audit_trail
      ?.filter((el) => el.reviewed_at || el.updated_at)
      ?.sort((first, second) => first.approval_level - second.approval_level)
      .map((el) => {
        return {
          name: (el?.first_name || "") + " " + (el?.last_name || ""),
          action: getAuditTrailAction(el),
          timeAgo: getTimeAgo(el.reviewed_at || el.updated_at || ""),
          comment: getApprovalComment(el),
          status: getAuditTrailStatus(el)
        };
      });
    return trailItem;
  };

  const generateTransactionDetails = (expense: SingleExpenseResponse) => {
    const expenseData: PDFReceiptType = {
      created_at: expense?.result?.created_at,
      amount: Number(expense?.result?.amount || "0"),
      account_name: expense?.transaction?.receiver_details?.account_name,
      bank_name: expense?.transaction?.receiver_details?.bank_name,
      account_number: expense?.transaction?.receiver_details?.account_number,
      txn_status: expense?.transaction?.status,
      transaction_ref: expense?.transaction?.transaction_ref,
      description: expense?.transaction?.description
    };

    return expenseData;
  };
  return (
    <RequestDetailsContainer>
      <RequestDetailsContainerEl>
        <div className="details-header ">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4>Transaction Details</h4>
            {expense?.result?.status && (
              <PayoutStatusContainer
                className={getPayoutStatusColor(expense?.result?.status || "")}
              >
                {transformPayoutStatus(expense?.result?.status || "")}
              </PayoutStatusContainer>
            )}
          </div>
        </div>

        <div
          style={{
            background: "#ffffff",
            border: "1px solid #ECF0F3",
            padding: "20px",
            marginTop: "5px"
          }}
        >
          <div>
            <div className="el-header">REQUEST INFORMATION</div>

            <div className="request-info-container">
              <p>Head of department </p>
              <h3>
                {(expense?.result?.department?.hod?.first_name || "") +
                  (expense?.result?.department?.hod?.last_name || "")}
              </h3>
            </div>
            <div className="request-info-container">
              <p>Amount</p>
              <h3>
                {" "}
                <NairaFormatter
                  amount={String(expense?.result?.amount || "0")}
                />
              </h3>
            </div>
            <div className="request-info-container">
              <p>purpose</p>
              <h3>{expense?.result?.purpose}</h3>
            </div>
            <div className="request-info-container">
              <p>Category</p>
              <h3>
                {expense?.result?.expense_category?.expense_account_alias}
              </h3>
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="el-header">VENDOR&apos;S INFORMATION</div>

            <div className="request-info-container">
              <p>Vendor&apos;s Name</p>
              <h3>{expense?.result?.vendor?.recipient_name || "-"}</h3>
            </div>
            <div className="request-info-container">
              <p>Bank Name</p>
              <h3>{expense?.result?.vendor?.recipient_bank_name || "-"}</h3>
            </div>
            <div className="request-info-container">
              <p>Account Number</p>
              <h3>
                {expense?.result?.vendor?.recipient_account_number || "-"}
              </h3>
            </div>
          </div>

          {/* payment details */}

          {expense?.transaction?.status && (
            <div style={{ marginTop: "20px" }}>
              <div className="el-header">PAYMENT DETAILS</div>

              <div className="request-info-container">
                <p>Payment date</p>
                <h3>
                  {moment(expense?.transaction?.date).format("DD/MM/yyyy") ||
                    "-"}
                </h3>
              </div>
              <div className="request-info-container">
                <p>Payment status</p>
                <PayoutStatusContainer
                  style={{ width: "fit-content" }}
                  className={getPayoutStatusColor(
                    getPaymentStatus(expense?.transaction?.status || "")
                  )}
                >
                  {transformPayoutStatus(
                    getPaymentStatus(expense?.transaction?.status || "")
                  )}
                </PayoutStatusContainer>
              </div>
              <div className="request-info-container">
                <p>Transaction Reference</p>
                <h3>{expense?.transaction?.transaction_ref || "-"}</h3>
              </div>

              <div className="request-info-container">
                <p>Download Receipt</p>

                {expense && (
                  <DownloadReceiptContainer
                    style={{ marginTop: "0", width: "fit-content" }}
                  >
                    <PDFDownloadLink
                      document={
                        <PDFRenderer
                          transactionDetails={generateTransactionDetails(
                            expense
                          )}
                        />
                      }
                      fileName="transaction_receipt.pdf"
                    >
                      {({ loading }: { loading: boolean }) =>
                        loading ? "Generating PDF..." : " Download Receipt"
                      }
                    </PDFDownloadLink>

                    <FileIcon width={35} style={{ marginLeft: "3px" }} />
                  </DownloadReceiptContainer>
                )}
              </div>
            </div>
          )}
        </div>
      </RequestDetailsContainerEl>
      <div>
        <RequestDetailsContainerEl style={{ padding: "0", marginTop: "0" }}>
          <div
            style={{
              background: "#ffffff"
            }}
          >
            <div
              className="el-header"
              style={{ marginTop: "0", fontSize: "14px" }}
            >
              ATTACHED DOCUMENTS
            </div>

            <div style={{ padding: "20px" }}>
              {expense?.result?.supporting_documents?.map((el, index) => (
                <UploadItemContainer key={index}>
                  <div style={{ display: "flex" }}>
                    <FileIcon width={35} style={{ marginRight: "5px" }} />

                    <p>{truncateString(el?.split("/").pop() || "", 30)}</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a
                      style={{
                        color: "#2DB49E",
                        cursor: "pointer",
                        textDecoration: "none"
                      }}
                      onClick={() => triggerDocumentPreviewModal(el)}
                    >
                      View
                    </a>
                    <p style={{ margin: "0 3px" }}>|</p>
                    <a
                      href={el}
                      target="_blank"
                      rel="noreferrer"
                      download
                      style={{
                        color: "#2DB49E",
                        cursor: "pointer",
                        textDecoration: "none"
                      }}
                    >
                      Download
                    </a>
                  </div>
                </UploadItemContainer>
              ))}
            </div>
          </div>
        </RequestDetailsContainerEl>

        <RequestDetailsContainerEl
          style={{ padding: "0 10px", marginTop: "20px" }}
        >
          <div>
            <div
              style={{
                background: "#ffffff"
              }}
            >
              <div
                className="el-header"
                style={{ marginTop: "10px", fontSize: "14px" }}
              >
                DEPARTMENT APPROVER TRAIL
              </div>
            </div>
            <div style={{ margin: "20px 20px 0 20px" }}>
              <AuditTrailComponent
                steps={getDepartmentTrailInfo()}
                isLastVertical={true}
              />
            </div>
          </div>

          <div style={{ marginBottom: "30px" }}>
            <div
              style={{
                background: "#ffffff"
              }}
            >
              <div
                className="el-header"
                style={{ marginTop: "0px", fontSize: "14px" }}
              >
                FINANCE TEAM APPROVER TRAIL
              </div>
            </div>

            <div style={{ margin: "20px 20px 0 20px" }}>
              <AuditTrailComponent
                steps={getFinanceTeamTrailInfo()}
                isLastVertical={false}
              />
            </div>
          </div>
        </RequestDetailsContainerEl>
      </div>
    </RequestDetailsContainer>
  );
};

export default ExpenseDetails;
