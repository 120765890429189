import { queryClient } from "config/queryClient";
import {
  ForgotPasswordInterface,
  LoginInterface,
  ResetPasswordSubmitInterface,
  SignUpSubmitInterface
} from "interfaces/auth";
import { Errors } from "interfaces/errors";
import { TeamMembersFilterInterface } from "interfaces/filter";
import {
  ActivateTeamMemberPayload,
  IRolesResponseObject,
  ITeamMemberResponseObject,
  MerchantInterface,
  ProfileUpdatePayload,
  TeamMemberPayload,
  UpdateTeamMemberInterface,
  IAllTeamMembersResponse,
  AddonTeamMemberRolePayload,
  RemoveOnTeamMemberRolePayload,
  CustomRolePayload
} from "interfaces/users";
import * as userClient from "lib/api/user";
import { InitialDataFunction, Query, useMutation, useQuery } from "react-query";
import { useQueryConfigLite } from "utils/constants";

function useLogin(options = {}) {
  return useMutation<
    { merchant: MerchantInterface; user: ITeamMemberResponseObject },
    Errors,
    LoginInterface
  >(userClient.login, options);
}

function useSignup(options = {}) {
  return useMutation<{}, Errors, SignUpSubmitInterface>(
    userClient.signup,
    options
  );
}

function useVerifyToken(options = {}) {
  return useMutation<{}, Errors, { token: string }>(
    userClient.verifyToken,
    options
  );
}

function useProfileUpdate(options = {}) {
  return useMutation<{}, Errors, ProfileUpdatePayload>(
    userClient.profileUpdate,
    options
  );
}

function useForgotPassword(options = {}) {
  return useMutation<{}, Errors, ForgotPasswordInterface>(
    userClient.forgotPassword,
    options
  );
}

function useResetPassword(options = {}) {
  return useMutation<{}, Errors, ResetPasswordSubmitInterface>(
    userClient.resetPassword,
    options
  );
}

function useGetUserDetail(user_id: string, business_id: string) {
  const { data, status } = useQuery(
    ["user-details" + business_id],
    () => userClient.getUserDetail({}, user_id, business_id),
    useQueryConfigLite
  );

  return {
    data: data || ({} as ITeamMemberResponseObject),
    status
  };
}

function useGetAllTeamMembers(
  merchant_id: string,
  business_id: string,
  params?: TeamMembersFilterInterface,
  roleType?:
    | "Approver"
    | "Payer"
    | "Owner"
    | "Admin"
    | "Approver,Owner,Admin"
    | "Approver,Admin"
    | "Initiator"
    | "Initiator,Payer"
) {
  const { data, status } = useQuery(
    ["all-team-members" + business_id, params],
    () =>
      userClient.getAllTeamMembers(
        {},
        merchant_id,
        business_id,
        roleType,
        params
      ),
    useQueryConfigLite
  );

  return {
    data: data || ([] as ITeamMemberResponseObject[]),
    status
  };
}

export const refetchTeamMembers = (
  merchantId: string,
  params: TeamMembersFilterInterface,
  extras: { search_term?: string } = {}
) => {
  params.search_term = extras.search_term;

  return queryClient.fetchQuery<
    IAllTeamMembersResponse,
    Error,
    IAllTeamMembersResponse
  >(
    [`filtered-list-of-members_${params.page}`, merchantId, params],
    () => {
      return userClient.fetchTeamMembers(merchantId, params);
    },
    {
      initialData: function init() {
        const queryCache = queryClient.getQueryCache();
        const queries = queryCache.findAll("all-team-members", {
          exact: false,
          active: true,
          inactive: true,
          stale: true
        }) as Query<IAllTeamMembersResponse, Error, IAllTeamMembersResponse>[];
        const data = queries.reduce(
          (collectedData: ITeamMemberResponseObject[], currentQuery) => {
            const accumulatedData = collectedData.concat(
              currentQuery.state.data?.data || []
            );
            return accumulatedData;
          },
          [] as ITeamMemberResponseObject[]
        );

        let pageCount = 1;

        if (params.perPage) {
          pageCount = Math.ceil(data.length / params.perPage);
        }

        /* @ts-ignore */
        data.__fromCache = true;

        return {
          data,
          meta: {
            page: 1,
            pageCount,
            nextPage: pageCount > 1,
            previousPage: false,
            total: data.length,
            perPage: params.perPage,
            limit: params.limit
          }
        };
      } as InitialDataFunction<IAllTeamMembersResponse>
    }
  );
};

function useCreateTeamMember(options = {}) {
  return useMutation<ITeamMemberResponseObject, Errors, TeamMemberPayload>(
    userClient.createTeamMember,
    options
  );
}

function useActivateTeamMember(options = {}) {
  return useMutation<object, Errors, ActivateTeamMemberPayload>(
    userClient.activateTeamMember,
    options
  );
}

function useReactivateTeamMember(options = {}) {
  return useMutation<
    object,
    Errors,
    { email: string; account_reactivation: boolean; business_id?: string }
  >(userClient.reactivateTeamMember, options);
}

function useGetRoles(role: string, business_id: string) {
  const { data, status } = useQuery(["list-of-roles" + business_id], () =>
    userClient.getRoles(role, business_id)
  );
  return {
    data: data || ([] as IRolesResponseObject[]),
    status
  };
}

function useAssignAdditionalRoles(options = {}) {
  return useMutation<object, Errors, AddonTeamMemberRolePayload>(
    userClient.assignAdditionalRoles,
    options
  );
}

function useUnAssignAdditionalRoles(options = {}) {
  return useMutation<object, Errors, RemoveOnTeamMemberRolePayload>(
    userClient.unassignAdditionalRoles,
    options
  );
}

function useResendMemberInvite(email: string) {
  const { data, status } = useQuery(["member-invite", email], () =>
    userClient.resendMemberInvite(email)
  );
  return { data, status };
}

function useCreateCustomRole(options = {}) {
  return useMutation<object, Errors, CustomRolePayload>(
    userClient.createCustomRole,
    options
  );
}

function useUpdateTeamMember(options = {}) {
  return useMutation<
    ITeamMemberResponseObject,
    Errors,
    UpdateTeamMemberInterface
  >(userClient.updateTeamMemberDetails, options);
}

export {
  useLogin,
  useSignup,
  useProfileUpdate,
  useForgotPassword,
  useResetPassword,
  useVerifyToken,
  useGetRoles,
  useCreateTeamMember,
  useActivateTeamMember,
  useReactivateTeamMember,
  useAssignAdditionalRoles,
  useUnAssignAdditionalRoles,
  useGetAllTeamMembers,
  useResendMemberInvite,
  useUpdateTeamMember,
  useCreateCustomRole,
  useGetUserDetail
};
