const isStaging_Deploy =
  process.env.NODE_ENV === "production" &&
  (process.env.REACT_APP_API_URL || "").search(/\.(staging)\./) !== -1;
const isProd_Deploy =
  process.env.NODE_ENV === "production" &&
  (process.env.REACT_APP_API_URL || "").search(/\.(stag(?:ing)?|dev)\./) === -1;

export default {
  TOKEN: "__ACCESS_TOKEN__",
  DUMMY_ORIGIN: "http://host",
  HEADWAY_ACCOUNT_ID: process.env.REACT_APP_HEADWAY_ACCOUNT_ID,
  API_ENDPOINT: process.env.REACT_APP_API_URL,
  BNPL_API_ENDPOINT: process.env.REACT_APP_API_BNPL_URL,
  APPROVAL_POLICIES_API: process.env.REACT_APP_APPROVAL_POLICIES_API_URL,
  APPROVAL_WORKFLOW_API: process.env.REACT_APP_WORKFLOW_API_URL,
  BILLS_API: process.env.REACT_APP_BILLS_API_URL,
  WALLET_API: process.env.REACT_APP_WALLET_API_URL,
  WALLET_API_STAGING: process.env.REACT_APP_WALLET_API_URL_STAGING,
  OTP_API: process.env.REACT_APP_OTP_SERVICE,
  INTEGRATION_API: process.env.REACT_APP_INTEGRATION_SERVICE,
  PUBLIC_URL: process.env.PUBLIC_URL,
  DUPLO_KEY: process.env.REACT_APP_MERCHANT_DEFAULT_HEADER,
  BNPL_KEY: process.env.REACT_APP_BNPL_DEFAULT_HEADER,
  WALLET_KEY: process.env.REACT_APP_WALLET_DEFAULT_HEADER,
  MERCHANT_API: process.env.REACT_APP_MERCHANT_API_URL,
  TIME_OUT: Number(process.env.REACT_APP_IDLE_TIME),
  EXCLUDE_BANK: process.env.REACT_APP_BANK_EXCLUDE,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
  PROD: process.env.NODE_ENV === "production",
  ENV: process.env.NODE_ENV,
  IS_PROD_DEPLOYED: isProd_Deploy,
  IS_STAGING_DEPLOYED: isStaging_Deploy,
  ENCRYPTION_KEY: process.env.REACT_APP_ENCRYPTION_KEY,
  MAILBOX_VALIDATOR_URL: process.env.REACT_APP_MAILBOX_VALIDATOR_URL,
  MAILBOX_VALIDATOR_KEY: process.env.REACT_APP_MAILBOX_VALIDATOR_KEY,
  PROD_HOST: "app.tryduplo.com",
  BUGSNAG: {
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY
  },
  DEV_FEATURES_LIST: [
    /* @HINT: all features shown to a user on {https://app.dev.tryduplo.com} LOCAL deploy (development env) except the ones with an underscore at the end */

    "overview" /* Overview Page (Function/Link On Side Menu-Item Page) */,
    "apps" /* Apps Page (Function/Link On Side Menu-Item Page) */,
    "payouts" /* Payouts Page (Function/Link On Side Menu-Item Page) */,
    "settings" /* Settings Page (Function/Link On Side Menu-Item Page) */,
    "invoices" /* Invoices Page (Function/Link On Side Menu-Item Page) */,
    "business" /* Business Page (Function/Link On Side Menu-Item Page) */,
    "expenses" /* Expenses Page (Function/Link On Side Menu-Item Page) */,
    "profile" /* Profile Page (Function/Link On Title Pane Dropdown Menu-Item Page) */,
    "bills" /* Bills Page (Function/Link On Side Menu-Item Page) */,
    "recipients" /* Recipients Page (Function/Link On Side Menu-Item Page) */,
    "customers" /* Customers Page (Function/Link On Side Menu-Item Page) */,
    "virtual_accounts" /* Virtual Accounts Page (Function/Link On Side Menu-Item Page) */,
    "international" /* International Page (Function/Link On Side Menu-Item Page) */,
    "get_started" /* Get Started Page (Function/Link On Side Menu-Item Page) */,
    "payouts:management:recipients:basic" /* Make New Payout (Function/Button On Payouts Page) */,
    "payouts:management:recipients:bulk" /* Make New Bulk Payouts (Function/Button On Payouts Page) */,
    "payouts:management:recipients:scheduled" /* Make New Scheduled Payouts (Function/Button On Payouts Page) */,
    "apps:management:list_" /* Apps Page List (Grid List On Apps Page) */,
    "international:remittances_" /* Automated Remittance Controls (Function On International Page) */,
    "settings:management:teams:view_only" /* User Management (View_Only Role) */,
    "settings:management:teams:payer" /* User Management (Payer Role) */,
    "settings:management:teams:approver" /* User Management (Approver Role) */,
    "settings:management:teams:admin" /* User Management (Admin Role) */,
    "settings:management:teams:owner" /* User Management (Owner Role) */,
    "customers:management:wallets:bulk" /* Add New Bulk Customers (Function/Button On Create Customers Page) */,
    "customers:management:wallets:basic" /* Add New Customer (Function/Button On Customers Page) */,
    "customers:management:wallets:basic:edit" /* Edit Existing Customer (Function/Button On Customer Details Page) */,
    "virtual_accounts:viewership:wallets" /* View Virtual Accounts In Tabular Form (Function On Virtual Accounts Page) */,
    "recipients:management:customers:basic" /* Add New Recipients (Function/Button On Recipients Page)  */,
    "invoices:management:draft" /* Add New [Draft] Invoice (Function On Create Invoice Page) */,
    "invoices:exports:print" /* Print Existing Invoice (Function On All Invoices Page) */,
    "invoices:management:basic" /* Add New [Final] Invoice (Function On Create Invoice Page) */,
    "invoices:management:basic:edit" /* Edit Existing [Draft] Invoice (Function On Invoice Page) */,
    "invoices:management:preview" /* Preview Existing [Draft/Final] Invoice (Function On Invoice Page) */,
    "settings:management:business" /* Business Details Preview/Edit Page (Function On Setting Page - Business Tab) */,
    "settings:management:preference",
    "settings:management:developer",
    "settings:management:approvals_workflow",
    "settings:approval_workflow:edit_",
    "settings:management:complaince",
    "settings:management:settlement_",
    "settings:management:portal_",
    "settings:management:preference:email_notifications",
    "settings:management:settlement:banks:config",
    "settings:management:portal:config",
    "settings:management:preference:invoice_reminders",
    "settings:management:developer:api_keys",
    "settings:management:developer:web_hook",
    "settings:management:teams",
    "settings:management:payout",
    "security:management:password_auth",
    "security:management:google_auth",
    "settings:compliance:verification:sole" /* Update [Sole Proprietorship] Complaince Details (Function/Button On Settings Page - Complaince Tab) */,
    "settings:compliance:verification:limited" /* Update [Limited Liability] Complaince Details (Function/Button On Setting Page - Complaince Tab) */,
    "profile:management:basic:edit:bio",
    "profile:management:basic:edit:logo",
    "recipients:management:vendor:type" /* Add New Recipients (Vendor/Others Type) (Function/Button On Recipients Page)  */,
    "payouts:bulk:create" /* Create bulk payouts  */
  ],
  PROD_FEATURES_LIST: [
    /* @HINT: all features shown to a user on {https://app.staging.tryduplo.com , https://app.tryduplo.com} STAGING & LIVE deploy (production env)  */

    "overview" /* Overview Page (Function/Link On Side Menu-Item Page) */,
    "payouts" /* Payouts Page (Function/Link On Side Menu-Item Page) */,
    "settings" /* Settings Page (Function/Link On Side Menu-Item Page) */,
    "invoices" /* Invoices Page (Function/Link On Side Menu-Item Page) */,
    "business" /* Business Page (Function/Link On Side Menu-Item Page) */,
    "profile" /* Profile Page (Function/Link On Title Pane Dropdown Menu-Item Widget) */,
    "recipients" /* Recipients Page (Function/Link On Side Menu-Item Page) */,
    "customers" /* Customers Page (Function/Link On Side Menu-Item Page) */,
    "virtual_accounts" /* Virtual Accounts Page (Function/Link On Side Menu-Item Page) */,
    "international_" /* International Page (Function/Link On Side Menu-Item Page) */,
    "payouts:management:recipients:basic" /* Make New Payout (Function/Button On Payouts Page) */,
    "payouts:management:recipients:bulk" /* Make New Bulk Payouts (Function/Button On Payouts Page) */,
    "payouts:management:recipients:scheduled" /* Make New Scheduled Payouts (Function/Button On Payouts Page) */,
    "settings:management:teams:view_only" /* User Management (View_Only Role) */,
    "settings:management:teams:payer" /* User Management (Payer Role) */,
    "settings:management:teams:approver" /* User Management (Approver Role) */,
    "settings:management:teams:admin" /* User Management (Admin Role) */,
    "settings:management:teams:owner" /* User Management (Owner Role) */,
    "customers:management:wallets:bulk" /* Add New Bulk Customers (Function/Button On Create Customers Page) */,
    "customers:management:wallets:basic" /* Add New Customer (Function/Button On Customers Page) */,
    "customers:management:wallets:basic:edit" /* Edit Existing Customer (Function/Button On Customer Details Page) */,
    "virtual_accounts:viewership:wallets" /* View Virtual Accounts In Tabular Form (Function On Virtual Accounts Page) */,
    "recipients:management:customers:basic" /* Add New Recipients (Function/Button On Recipients Page)  */,
    "invoices:management:draft" /* Add New [Draft] Invoice (Function On Create Invoice Page) */,
    "settings:compliance:verification:sole" /* Update [Sole Proprietorship] Complaince Details (Function/Button On Settings Page - Complaince Tab) */,
    "settings:compliance:verification:limited" /* Update [Limited Liability] Complaince Details (Function/Button On Settings Page - Complaince Tab) */,
    "invoices:management:basic" /* Add New [Final] Invoice (Function On Create Invoice Page) */,
    "invoices:exports:print" /* Print Existing Invoice (Function On All Invoices Page) */,
    "invoices:management:preview" /* Preview Existing [Draft/Final] Invoice (Function On Invoice Page) */,
    "invoices:management:basic:edit" /* Edit Existing [Draft] Invoice (Function On Invoice Page) */,
    "settings:management:business" /* Business Details Preview/Edit Page (Function On Setting Page - Business Tab) */,
    "settings:management:preference",
    "settings:management:developer",
    "settings:management:complaince",
    "settings:management:approvals_workflow",
    "settings:management:preference:email_notifications",
    "settings:management:portal:config",
    "settings:management:preference:invoice_reminders",
    "settings:management:teams",
    "settings:management:payout",
    "security:management:password_auth",
    "security:management:google_auth",
    "profile:management:basic:edit:bio",
    "profile:management:basic:edit:logo",
    "payouts:bulk:create" /* Create bulk payouts  */
  ].concat(
    isStaging_Deploy
      ? [
          /* @HINT: additional features shown to a user on {https://app-staging.tryduplo.com} STAGING deploy (production env) except the ones with an underscore at the end */
          "expenses" /* Expenses Page (Function/Link On Side Menu-Item Page) */,
          "bills" /* Bills Page (Function/Link On Side Menu-Item Page) */,
          "apps" /* Apps Page (Function/Link On Side Menu-Item Page) */,
          "apps:management:list_" /* Apps Page List (Grid List On Apps Page) */,
          "international",
          "international:remittances_" /* Automated Remittance Controls (Function On International Page) */,
          "get_started_" /* Get Started Page (Function/Link On Side Menu-Item Page) */,
          "settings:management:settlement_",
          "settings:management:portal_",
          "recipients:management:vendor:type",
          "settings:approval_workflow:edit_"
        ]
      : [
          /* @HINT: additional features shown to a user on {https://app.tryduplo.com} LIVE deploy (production env) except the ones with an underscore at the end */

          "bills" /* Bills Page (Function/Link On Side Menu-Item Page) */,
          "apps" /* Apps Page (Function/Link On Side Menu-Item Page) */,
          "apps:management:list_" /* Apps Page List (Grid List On Apps Page) */,
          "international:remittances_" /* Automated Remittance Controls (Function On International Page) */,
          "get_started_" /* Get Started Page (Function/Link On Side Menu-Item Page) */,
          "settings:management:settlement_",
          "settings:management:portal_",
          "recipients:management:vendor:type",
          "settings:approval_workflow:edit_",
          "expenses" /* Expenses Page (Function/Link On Side Menu-Item Page) */
        ]
  ),
  NEWRELIC_APPLICATION_ID: process.env.REACT_APP_NEWRELIC_APPLICATION_ID,
  NEWRELIC_LICENSE_ID: process.env.REACT_APP_NEWRELIC_LICENSE_ID,
  NEWRELIC_BEACON_HOST: process.env.REACT_APP_NEWRELIC_BEACON_HOST,
  NEWRELIC_ACCOUNT_ID: process.env.REACT_APP_NEWRELIC_ACCOUNT_ID,
  RUDDERSTACK_WRITE_KEY: process.env.REACT_APP_RUDDERSTACK_APP_KEY,
  RUDDERSTACK_DATA_PLAIN_URL: process.env.REACT_APP_RUDDERSTACK_DATAPLANE_URL,
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_S3_BUCKET: process.env.REACT_APP_AWS_S3_BUCKET,
  AWS_REGION: process.env.REACT_APP_AWS_REGION
};
